.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.swal2-container {
  z-index: 2000 !important;
}

.react-grid-layout .react-grid-item {
  border: 1px solid #ccc;
}

.react-grid-layout .react-grid-item .react-resizable-handle {
  width: 100%;
  height: 20px;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  cursor: row-resize;
}

.react-grid-layout .react-grid-item:nth-of-type(2) .react-resizable-handle {
  display: none;
}
